@import 'style/color.scss';
@import 'style/font.scss';

// ==============================|| Intro ||============================== //

.CompanyIntro {
  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// ==============================|| MoveBusiness ||============================== //

.M-MoveBusiness {
  height: 28.3rem;
  background-color: $gray4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4rem;
}

.MoveBusiness {
  height: 45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.MoveBusiness-Button {
  padding: 1rem 2rem;
  border-radius: 2.9rem;
  background-color: rgba(0, 0, 0, 0.6);
}

// ==============================|| CompanyHistory ||============================== //

.CompanyHistory {
  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2.4rem;
      object-fit: cover;
    }
  }
}

// ==============================|| CompanyManagement ||============================== //

.CompanyManagement {

  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2.4rem;
      object-fit: cover;
    }
  }
}


// ==============================|| CompanyLocation ||============================== //

.CompanyLocation {
  background-color: $gray1;
  border-bottom: 1px solid $gray3;

  &-Map {
    border-radius: 2.4rem;
    border: none;
  }

}