@import 'style/color.scss';
@import 'style/font.scss';

.Service {
  &-Button {
    z-index: 10;
    background-color: $gray2;
    border-radius: 35px;

  }
}

.Service {
  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
    }
  }
}

.Service-Fancast {
  &-Logo-Wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(57 / 222 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }


  &-Dropdown-Item-Wrapper {
    z-index: 30;
    position: absolute;
    width: 100%;
    padding: 0.8rem 0;
    border-radius: 24px;
    background-color: $white1;
    box-shadow: 3px 5px 15px #0000000D;
  }

  &-Dropdown-Item {
    display: block;
    text-align: center;
    width: 100%;
    padding: 1.6rem 0;
    border-radius: 35px;
    color: $black2;
    font-size: 1.5rem;
    letter-spacing: -0.028rem;
  }
}

.Service-Fanseem {
  &-Logo-Wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(39 / 222 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}