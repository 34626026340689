@import 'layouts/header';
@import 'layouts/footer';
@import 'layouts/languageDropdown';

@import 'views/common';
@import 'views/company';
@import 'views/business';
@import 'views/service';
@import 'views/news';

// ----------------------------------------------------------------------

html {
  height: 100vh;
  background-color: $gray1;
}

@media (min-width: 375px) {
  html {
    font-size: 62.5%;
  }
}

@media (max-width: 374px) {
  html {
    font-size: 50%;
  }
}

body {
  margin: 0;
  font-family: 'Pretendard Variable', 'Pretendard', 'Roboto', sans-serif !important;

  &.jp {
    font-family: 'Pretendard JP', 'Pretendard JP Variable', 'Pretendard', 'Roboto', sans-serif !important;
  }
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}


*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bcc1c7;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #e8e8ec;
}

*::-webkit-scrollbar-button {
  display: none;
}