@import 'style/color.scss';
@import 'style/font.scss';

.NewsInfo {

  &-Card {
    cursor: pointer;
  }

  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.NewsOfficial {

  &-Item {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover .NewsOfficial-Arrow {
      stroke: $black1;
    }
  }
}

.NewsDetail {
  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }
}

.News-List {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    background-color: $gray1;
    border-radius: 20px;
  }
}