@import 'style/color';
@import 'style/font';

.Footer {
  background-color: $gray1;
}

.Footer {
  &-Nav-Main {
    &-Item {
      font-size: 1.5rem;
      font-weight: $bold;
      color: $black1;
    }

  }

  &-Nav-Sub {
    padding: 0;
    margin: 0;
    list-style: none;
    color: $black2;

    &-Item {
      font-size: 1.4rem;
      cursor: pointer;
      color: $black2;

      &:hover {
        font-weight: $bold;
      }
    }
  }
}


.M-Footer-Site {
  &-Wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
  }
  &-Dropdown {
    z-index: 10;
    width: 100%;
    padding: 1.2rem 2.4rem;
    border-radius: 12px;
    background-color: $white1;
    color: $black2;
    font-size: 1.4rem;
    letter-spacing: -0.028rem;
  }
}

.Footer-Site {
  &-Wrapper {
    position: relative;
  }
  &-Dropdown {
    padding: 1.2rem 2.4rem;
    border-radius: 12px;
    background-color: $white1;
  }
  &-Dropdown-Item-Wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0.8rem 0;
    border-radius: 12px;
    background-color: $white1;
    box-shadow: 3px 5px 15px #0000000D;
  }
  &-Dropdown-Item {
    display: block;
    text-align: center;
    width: 100%;
    padding: 1.2rem 0;
    border-radius: 12px;
    color: $black2;
    font-size: 1.4rem;
    letter-spacing: -0.028rem;
  }
}
