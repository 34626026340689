@import 'style/color';
@import 'style/font';

.Header-Logo {
  cursor: pointer;
  width: 112px;
  height: 39px;
}

.M-Header-Logo {
  cursor: pointer;
  width: 100px;
  height: 35px;
}

.Header-Sticky {
  z-index: 1500;
  position: sticky;
  top: 0;
  background-color: $white1;
  border-bottom: 1px solid $gray3;
}

// ==============================|| Mobile ||============================== //

.M-Header {
  padding: 0;
  height: 56px;
}

.M-Header-Nav {
  padding-bottom: 12rem;

  &-Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    margin: 1rem 0;
  }

  &-Sub {
    &-Item {
      width: calc(100% - 4rem);
      text-align: left;
      display: block;
      color: $gray6;
      font-size: 1.8rem;
      margin-left: 2rem;
      padding: 1.2rem 2rem;
    }
  }
}

// ==============================|| PC ||============================== //

.Header {
  padding: 0;
  height: 65px;

  &-Left {
    display: flex;
    align-items: center;
  }

  &-Right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-Nav {
    height: 100%;
    display: flex;
    padding: 0;
    list-style: none;

    &-Item {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      padding: {
        left: 3rem;
        right: 3rem;
      }
      font-size: 1.6rem;
      font-weight: $regular;

      a {
        color: $black1;
      }

      &:hover {
        font-weight: $semiBold;
      }

      &:hover &-Menu {
        display: block;
        position: absolute;
        top: 63px;
        left: 0;
        width: 100%;
        list-style: none;
        background-color: $white1;
      }

      &-Menu {
        position: absolute;
        display: none;
        padding: {
          top: 1.2rem;
          bottom: 1rem;
          left: 0;
          right: 0;
        }
        list-style: none;
        text-align: center;
        border-radius: 0 0 24px 24px;
        box-shadow: 3px 5px 15px #0000000D;

        &:hover {
          display: block;
        }

        &-Item {
          cursor: pointer;
          font-size: 1.5rem;
          font-weight: $regular;
          padding: {
            top: 1.5rem;
            bottom: 1.5rem;
          }

          a {
            color: $black2;
          }

          &:hover {
            font-weight: $semiBold;
          }
        }
      }
    }
  }
}
