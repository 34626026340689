@import 'style/color.scss';
@import 'style/font.scss';

.M-Business-Intro {
  &-Img {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 16rem;
    height: 14rem;
    margin-bottom: 1rem;

    &-Box {
      width: 16rem;
      height: 11rem;
      background-color: $gray1;
      border-radius: 24px;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 16rem;
      height: 14rem;
    }
  }
}

.M-BusinessIntro {
  &-Keyword {
    font-size: 1.8rem;
    font-weight: $bold;
    color: $black1;
  }
}

.BusinessIntro {
  &-Keyword-Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-Keyword {
    width: 18rem;
    height: 18rem;
    border-radius: calc(18rem / 2);
    border: 1px solid $black2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.Business-Intro {
  &-Img {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 28.2rem;
    height: 22.8rem;

    &-Box {
      width: 28.2rem;
      height: 18rem;
      background-color: $gray1;
      border-radius: 24px;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 28.2rem;
      height: 22.8rem;
    }
  }
}

.M-Business-Intro-Graph {
  height: 68rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Business-Intro-Graph {
  height: 55.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &-Bar {
    background-color: $white1;
    border-radius: 4rem;
  }
}


.Business-Partner {

  &-Item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white1;
  }

  &-Icon {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(90 / 160 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}


.M-Linked-Business {

  &-Button {
    margin-top: 0.7rem;
    padding-top: 0.8rem;
    border-bottom: 1px solid $black1;
  }
}

.Linked-Business {
  background-color: $gray1;

  &-Button {
    border-radius: 3.7rem;
    border: 1px solid $gray4;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: $gray6;

    img {
      width: 1.6rem;
      height: 1.2rem;
      margin-left: 0.8rem;
    }
  }

  &-Box {

    &-Fancast {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2.4rem;
      border-radius: 2.4rem;
      background-color: $white1;
      border: 1px solid $blue300;

      &-Img-Wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(99 / 110 * 100%);

        img {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-Blue {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2.4rem;
      border-radius: 2.4rem;
      background-color: $blue100;


      &-Img-Wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(120 / 174 * 100%);

        img {
          position: absolute;
          bottom: 0;
          right: 1rem;
          width: 65%;
          height: 65%;
        }
      }

    }
  }
}


.Connection-Business {

  &-Category {
    border-top: 1px solid $gray3;
  }

  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(280 / 486 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
  }
}

.M-BusinessContact {
  &-Img {
    height: 33.2rem;
  }
}

.BusinessContact {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-Img {
    height: 48rem;
  }

  &-Button {
    width: fit-content;
    background-color: $black2;
    opacity: 40%;
    border-radius: 29px;
  }
}