$white1: #FFFFFF;

$black1: #2D2D33;
$black2: #111114;
$black3: #11111480;

$gray1: #F8F8FA;
$gray2: #F2F2F7;
$gray3: #DFDFE5;
$gray4: #C9C9D0;
$gray5: #9A9AA2;
$gray6: #62626A;

$blue100: #E2F1FF;
$blue300: #3372D8;

:export {
  white1: $white1;

  black1: $black1;
  black2: $black2;
  black3: $black3;

  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;

  blue100: $blue100;
  blue300: $blue300;
}