@import 'style/color.scss';
@import 'style/font.scss';

// ==============================|| MainImage ||============================== //

.M-MainImage {
  height: 400px;
}

.MainImage {
  height: 800px;

  &-Title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.M-MainImage-Small {
  height: 400px;
}

.MainImage-Small {
  height: 400px;

  &-Title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ==============================|| SubIndex ||============================== //

.M-SubIndex-Sticky {
  z-index: 50;
  position: sticky;
  top: 56px;
  background-color: $white1;
}

.SubIndex-Sticky {
  z-index: 50;
  position: sticky;
  top: 65px;
  background-color: $white1;
}

.SubIndex {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &-Item {
    cursor: pointer;
    color: $gray5;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.04rem;
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 4px solid white;

    &-Active {
      color: $black2;
      border-bottom: 4px solid $black2;
    }
  }
}

.M-SubIndex {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &-Item {
    color: $gray5;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.032rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid white;

    &-Active {
      color: $black2;
      border-bottom: 4px solid $black2;
    }
  }
}

// ==============================|| MoreView ||============================== //

.MoreView {
  &-Card {
    cursor: pointer;
    border-radius: 24px;
    background-color: $white1;
    box-shadow: 5px 10px 30px #0000000F;
  }

  &-Img-Wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(240 / 384 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      object-fit: cover;
    }
  }
}

// ==============================|| MinimalPagination ||============================== //

.M-MinimalPagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-Page {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
  }
}

.MinimalPagination {
  display: flex;
  align-items: center;

  &-Page {
    width: 100px;
    padding: 0 3rem;
    text-align: center;
  }

  &-Next-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    background-color: $gray1;
    border-radius: 20px;
  }
}
