@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-jp-dynamic-subset.min.css");

$thin: 100;
$extraThin: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;

:export {
  thin: $thin;
  extraThin: $extraThin;
  light: $light;
  regular: $regular;
  medium: $medium;
  semiBold: $semiBold;
  bold: $bold;
  extraBold: $extraBold;
  black: $black;
}