@import 'style/color';
@import 'style/font';

// ==============================|| Mobile ||============================== //

.M-LanguageDropdown {
  padding: 1.5rem;

  &-Item-Wrapper {
    position: absolute;
    top: 60px;
    padding: 1rem 0;
    border-radius: 12px;
    background-color: $white1;
    box-shadow: 3px 5px 15px #0000000D;
  }

  &-Item {
    width: 100%;
    padding: 1rem 0;
    border-radius: 12px;
    background-color: $white1;
    font-size: 1.5rem;
    color: $black2;

    &-Bold {
      font-weight: 700;
    }
  }
}

// ==============================|| PC ||============================== //

.LanguageDropdown {
  background-color: $gray1;
  border: 1px solid $gray2;
  border-radius: 18px;
  padding: 0.7rem 1.6rem;


  &-Item-Wrapper {
    width: 100%;
    position: absolute;
    top: 37px;
    padding: 0.6rem 0;
    background-color: $gray1;
    border-radius: 17px;
    border: 1px solid $gray2;
  }

  &-Item {
    width: 100%;
    padding: 0.8rem 0;
    background-color: $gray1;
    border-radius: 17px;
    font-size: 1.5rem;
    color: $black2;
  }
}